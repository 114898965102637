// In src/pages/Page.js
import React, { useEffect, useState } from 'react';
import './style/1018360.scss';

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import axios from 'axios';

import BlushLogo from '../assets/blush_side_logo_colored.svg';

const Image = (id, size, type) => `https://cdn.faktor.software/w_${size || 300}/e_trim/${id}.png`;
const formatISK = (price) => (price ? price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0');

const Sogtaeki1018360 = (props, { match }) => {
    const params = new URLSearchParams(props.location.search);
    const category = params.get('category');
    const brand = params.get('brand');
    const title = params.get('title');
    const subtitle = params.get('subtitle');

    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        async function fetchProducts() {
            let blushProducts = await axios.get('https://api.blush.is/api/products');
            if (blushProducts.data) {
                if (!category && !brand) {
                    setProducts(
                        blushProducts.data
                            .filter((p) => !!p.popularity)
                            .sort((a, b) => b.popularity - a.popularity)
                            .slice(0, 20)
                    );
                } else {
                    let arrangedProducts = blushProducts.data
                        .filter((p) => !!p.popularity)
                        .filter((p) =>
                            !p.sold_out && category ? p.categories.find((c) => c.slug === category) : p.brand?.slug === brand
                        );
                    console.log(arrangedProducts);
                    arrangedProducts = arrangedProducts.sort((a, b) => b.popularity - a.popularity);
                    arrangedProducts = arrangedProducts.slice(0, 20);
                    setProducts(arrangedProducts);
                }
            }
            setLoading(false);
        }
        fetchProducts();
    }, [category, brand]);

    if (loading) {
        return (
            <div className="blush-loading">
                <img src={BlushLogo} alt="" />
            </div>
        );
    }

    return (
        <div className="inpage-1018360">
            <img src={BlushLogo} alt="blush.is" className="logo" />
            <div className="upper">
                <p>{subtitle || 'Frábært úrval af'}</p>
                <h1>{title || 'Unaðsvörum'}</h1>
            </div>
            <AliceCarousel
                items={products.map((p) => (
                    <div className="product" onClick={(e) => window.open(`https://blush.is/product/${p.slug}`)}>
                        <img src={Image(p.images[p.main_image_index || 0].id)} alt={p.slug} />
                        <div className="label">
                            <p>{p.brand.name}</p>
                            <b>{p.name}</b>
                        </div>
                        <div className="price">
                            <b>{formatISK(p.price_from)} kr.</b>
                            {p.discount?.delta && <b className="priceBefore">{formatISK(p.price_from - p.discount.delta)} kr.</b>}
                        </div>
                    </div>
                ))}
                mouseTracking
                autoPlay
                infinite
                autoPlayInterval={2400}
            />
        </div>
    );
};

export default Sogtaeki1018360;
